import Parse from 'html-react-parser';
import { formatDate } from 'ddc-intl';
// Function get incentive by incentiveId
const getIncentive = (incentiveId, incentives) => {
	let trimsMaps = {};

	const incentive = incentives && incentives.length && incentives?.find(
		(item) => item.incentiveId === incentiveId
	);
	incentive?.trims?.forEach((trim) => {
		trim?.globalVehicleTrimIds?.forEach((val) => {
			trimsMaps = {
				...trimsMaps,
				[val]: {
					bodyStyle: trim.bodyStyle,
					trim: trim.trim,
				},
			};
		});
	});
	return trimsMaps;
};

// Function get incentive trim by globalVehicleTrimIds
export const getIncentiveTrimDescriptions = (promo, incentives) => {
	const { globalVehicleTrimIds, incentiveId } = promo;
	if (!globalVehicleTrimIds) {
		return [];
	}

	const trimsMaps = getIncentive(incentiveId, incentives);
	// loop through the list of gvTrimIds and get the string
	return globalVehicleTrimIds
		.map((gvtId) => {
			const gvTrim = trimsMaps[gvtId];
			return `${gvTrim?.trim} ${gvTrim?.bodyStyle}`;
		})
		.filter((v, i, arr) => {
			// dedupe the values in the array
			return arr.indexOf(v) === i;
		});
};

export const parseText = (str) => {
	return Parse(`${str.replace(/\n/g, '<br>')}`);
};

export const formatIncentiveDate = (incentive, locale) => {
	return {
		...incentive,
		effectiveDate: formatDate(incentive?.incentiveEffectiveDate,
			locale, { timeZone: 'UTC' }
		),
		expirationDate: formatDate(
			incentive?.incentiveExpirationDate,
			locale, { timeZone: 'UTC' }
		)
	}
}

export const checkObjectValid = (incentive) => {
	return typeof incentive === 'object' &&
		incentive !== null &&
		Object.keys(incentive).length > 0 &&
		incentive.constructor === Object
}

export const createVehicleDescrForIncentiveTitle = (
	hideIncentiveMake,
	showIncentiveTitleCondition,
	conditionLabel,
	showIncentiveTitleBodystyle,
	trim
) => {
	let applicableVehicle;
	// Create vehicle description for the title
	applicableVehicle =
		trim.year && trim.make && trim.model
			? `${trim.year} ${trim.make} ${trim.model}`
			: '';
	if (hideIncentiveMake && applicableVehicle) {
		applicableVehicle = `${trim.year} ${trim.model}`;
	}

	// Turn on additional title data with preferences
	if (showIncentiveTitleCondition === 'true') {
		applicableVehicle = `${conditionLabel} ${applicableVehicle}`;
	}
	if (showIncentiveTitleBodystyle === 'true') {
		applicableVehicle = `${applicableVehicle} ${trim.bodyStyle}`;
	}
	return applicableVehicle;
}
