import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLabels, useRequestData, useFlags } from 'wsm-common-data';
import { printViewOnChromeIos } from '../utilities/printViewOnChromeIos';
import { hasIosChrome } from '../utilities/utility';

const CtaPrint = ({ pushOutsiderCtaPrint, promo }) => {
	const labels = useLabels();
	const flags = useFlags();
	const { locale } = useRequestData();
	const incentives = useSelector((state) => state.incentives);
	const account = useSelector((state) => state.account);
	const isRemoveVcdaRequest = flags['remove-vcda-request'];

	const handleClick = () => {
		if (hasIosChrome(window.navigator.userAgent)) {
			// Print with pure function of window object
			printViewOnChromeIos(promo, labels, locale, incentives, account, isRemoveVcdaRequest);
		} else {
			// Print with react-to-print lib
			pushOutsiderCtaPrint();
		}
		
	}

	return (
		<div className='my-3 py-1 cta-print' role='none' onClick={handleClick}>
			<span className='ddc-icon ddc-icon-print' />
			<span>&nbsp;</span>
			<span>{labels.get('PRINT')}</span>
		</div>
	);
};

CtaPrint.propTypes = {
	pushOutsiderCtaPrint: PropTypes.func,
	promo: PropTypes.shape({})
}; 

export default CtaPrint;
